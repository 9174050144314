import React, { Component } from "react";
import CreditCard from "../../img/icons/credit_card.png";
import StoreFront from "../../img/icons/storefront.png";
import LocationOn from "../../img/icons/location_on.svg";
import DateRange from "../../img/icons/date_range.svg";
import "./MyDevices.css";
import "../common.css";
import config from "../../config";
import { FaPlus } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { Link, Redirect } from "react-router-dom";
import errorFunctions from "../../store/errorFunctions";
import LoadingPage from "../../components/Loading/Loading";
import utilityFunctions from "../../store/utilityFunctions";
import ShippingLabel from "./ShippingLabel/ShippingLabel";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";
import SetWarrantyDetails from "../Warranties/SetWarrantyDetails/SetWarrantyDetails";

import Modal from "react-modal";
Modal.setAppElement("body");

export default class MyDevices extends Component {
  constructor(props) {
    super(props);

    this.state = {
      myDevices: [],
      userDevice: null,
      noProfile: false,
      loading: true,
      showDeleteModal: false,
      showIosPopup: false,
      addWarrantyModal: false,
      showApproveModal: false,
      redirectToProfile: false,
      openModalHistory: false,
      currentHistoryTicket: [],
      showShipping: false,
      currentTicketId: "",
      ticket: null,
      filteredDevices: [],
      isWide: true,
    };
    this.lgnToken = localStorage.getItem("lgn") || utilityFunctions.getCookie("lgn");
    this.idUser = JSON.parse(this.lgnToken)?.user.id;
  }

  componentDidMount() {
    this.retrieveProfile();
    const isWide = window.screen.width > 600;
    this.setState({ isWide: isWide });
  }

  handleChange = (event) => {
    // const tempDevices = this.state.myDevices;
    // const tempDevices =
    //   this.state.myDevices.filter((x) => x.device.name.toLowerCase().includes(event.target.value.toLowerCase())) || this.state.myDevices;
    this.setState({ searchFilter: event.target.value });
  };

  retrieveProfile = () => {
    this.setState({ isLoading: true });
    this.props
      .protectedFetch(config.backendServer + `customers/${this.idUser}/`, "GET", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        this.setState({ isLoading: false });
        if (!res.first_name) window.location.href = "/profile";
        else this.retrieveDevices();
      })
      .catch(() => {
        console.log("fail");
        this.setState({ isLoading: false });
      });
  };

  retrieveDevices = () => {
    this.props
      .protectedFetch(config.backendServer + "user_devices/", "GET", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        this.setState({ loading: false, myDevices: res, filteredDevices: res });
      })
      .catch(() => {
        console.log("fail");
        this.setState({ loading: false });
      });
    this.setState({ loading: false });
  };
  downloadProofOfPurchase = (id, serial_number) => {
    // Can't download files on iOS. If iOS, set pop-up saying 'please use computer instead'
    const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    if (iOS) {
      this.setState({ showIosPopup: true });
      return; // Exit function now
    }

    this.setState({ loading: true });
    this.props
      .protectedFetch(config.backendServer + `user_device/${id}/download_proof/`, "GET", null, (res) => {
        return res; // Do everything in the 'then'
      })
      .then((res) => {
        if (res.ok === true || res.status === 200) {
          res.blob().then((res) => {
            const blob = res;
            blob.name = `Purchase_${serial_number}`;
            utilityFunctions.downloadFileAutomatically(blob, res.type);
          });
        } else {
          alert("error " + res.status);
        }
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.log("fail", err);
        this.setState({ loading: false });
      });
  };

  getResponseHeadersAsJSON(headers) {
    const contentDisposition = headers.get("content-disposition");
    const headersObj = {};

    if (contentDisposition) {
      const match = contentDisposition.match(/filename=(?<filename>.+)/);
      if (match && match.groups && match.groups.filename) {
        headersObj["filename"] = match.groups.filename;
      }
    }
    return headersObj;
  }
  handleDelete = () => {
    const deviceToDelete = this.state.myDevices[this.state.showDeleteModal].id; // Storing array id in showDeleteModal
    this.setState({ loading: true, showDeleteModal: false });
    this.props
      .protectedFetch(config.backendServer + "user_device/" + deviceToDelete + "/delete/", "DELETE", null, errorFunctions.checkResponseSent)
      .then((res) => {
        // Mimic a refresh of a page with the below
        this.componentDidMount();
      })
      .catch((err) => {
        console.log("fail", err);
        this.setState({ loading: false });
      });
  };
  handleAddWarranty = () => {
    const deviceId = this.state.myDevices[this.state.addWarrantyModal].id; // Storing array id
    this.setState({ loading: true, addWarrantyModal: false });
    this.props
      .protectedFetch(config.backendServer + "addnewwarranty", { deviceId }, errorFunctions.checkResponseSent)
      .then((res) => {
        // Mimic a refresh of a page with the below
        this.componentDidMount();
      })
      .catch((err) => {
        console.log("fail", err);
        this.setState({ loading: false });
      });
  };
  showShippingInfo = (ticket) => {
    this.setState({ showShipping: true, ticket: ticket });
  };

  render() {
    if (this.state.loading) {
      return <LoadingPage />;
    }
    if (this.state.redirectToProfile) {
      return <Redirect to="/profile" />;
    }
    let filteredDevice = [];
    if (this.state.searchFilter && this.state.myDevices) {
      this.state.myDevices.forEach((item) => {
        if (
          item.device.name?.toLowerCase().includes(this.state.searchFilter.toLowerCase()) ||
          item.seller_details.toLowerCase().includes(this.state.searchFilter.toLowerCase()) ||
          item.serial_number.toLowerCase().includes(this.state.searchFilter.toLowerCase()) ||
          item.store_location.toLowerCase().includes(this.state.searchFilter.toLowerCase())
        ) {
          filteredDevice.push(item);
        }
      });

      // filteredDevice.push(val));
    } else {
      this.state.myDevices.map((val) => filteredDevice.push(val));
    }

    // const myDevices = this.state.filteredDevices || this.state.myDevices;
    const that = this;

    const closedStatus = [
      "Did Not Proceed",
      "Not Approved",
      "Service Completed",
      "Completed",
      "Refund Processed",
      "Replacement Sent",
      "Repair complete, goods sent out",
    ];

    const webView = (
      <div className="row no-margin">
        <div className={"col"}>
          <h3>My Devices</h3>
        </div>
        <div className="col ">
          <div className="row text-end">
            <div className="input-group col mb-3 text-end">
              <input
                type="text"
                placeholder="Search tickets"
                id={"searchFilter"}
                className={"form-control"}
                onChange={(e) => this.handleChange(e)}
                // onKeyPress={this.handleKeyPressSearch}
                // onInput={()=> alert("submit")}
                // value={this.state.searchFilter}
                width={"100%"}
              />
              <div className="input-group-prepend">
                <span
                  className="form-control "
                  // onClick={this.handleBtnSearch}
                >
                  <SearchIcon />
                </span>
              </div>
            </div>

            <Link className="col-md-3" to="/addnewdevice">
              <button className={"k-btn k-btn-primary  k-btn-responsive "}>
                <FaPlus />
                <span>{"  Add New"}</span>
              </button>
            </Link>
          </div>
        </div>
      </div>
    );

    const mobileView = (
      <div>
        <div className="row">
          <div className={"col"}>
            <h3>My Devices</h3>
          </div>
          <Link className="col-md-3" to="/addnewdevice">
            <button className={"k-btn k-btn-primary k-btn-responsive mb-3"}>
              <FaPlus />
              <span>{"  Add New"}</span>
            </button>
          </Link>
        </div>
        <div>
          <div className="input-group col mb-3 text-end">
            <input
              type="text"
              placeholder="Search tickets"
              id={"searchFilter"}
              className={"form-control"}
              onChange={(e) => this.handleChange(e)}
              // onKeyPress={this.handleKeyPressSearch}
              // onInput={()=> alert("submit")}
              // value={this.state.searchFilter}
              width={"100%"}
            />
            <div className="input-group-prepend">
              <span
                className="form-control "
                // onClick={this.handleBtnSearch}
              >
                <SearchIcon />
              </span>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <div className={"page"}>
        {/* Add button for adding new 'My Device' */}
        {this.state.isWide ? webView : mobileView}
        {/* {window.screen.width > 600 ? webView : mobileView} */}

        <div className={"pageContent clearfix"}>
          {filteredDevice.map(function (item, i) {
            const lastTicket = item?.tickets[item.tickets.length - 1];
            const lastTicketStatus = lastTicket?.status;
            const isAddReport = !lastTicketStatus || closedStatus.includes(lastTicketStatus);
            return (
              <div
                key={i}
                className="device-card"
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = "/device-detail/" + item.id + "/";
                }}
              >
                <div className="row no-margin">
                  <div className="col-lg-2 col-md-12">
                    <img
                      src={item.device.image || "https://www.dreametech.com/cdn/shop/products/20220902103443_590x.png?v=1662089670"}
                      width={"100%"}
                      alt=""
                    />
                  </div>
                  <div className="col">
                    <div className="device-card-header"> {item.device.brand.name + " " + item.device.name}</div>
                    <div className="row ">
                      <div className="col-lg col-sm-12 device-card-line">
                        <img src={CreditCard} className="device-card-icon " alt="" />
                        <span> Series Number : {item.serial_number}</span>
                      </div>
                      <div className="col-lg col-sm-12 device-card-line">
                        <img src={StoreFront} className="device-card-icon" alt="" />
                        <span> Seller Details : {item.seller_details}</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg col-sm-12 device-card-line">
                        <img src={LocationOn} className="device-card-icon" alt="" />
                        <span> Store Location : {item.store_location}</span>
                      </div>
                      <div className="col-lg col-sm-12 device-card-line">
                        <img src={DateRange} className="device-card-icon" alt="" />
                        <span> Purchase Date : {item.date_of_purchase}</span>
                      </div>
                    </div>
                  </div>
                  <div className={"col-lg-2 col-sm-12 text-end"}>
                    {isAddReport && (
                      <button
                        className={"k-btn k-btn-primary k-btn-responsive"}
                        // onClick={() => that.setState({ addWarrantyModal: i })}
                        onClick={(e) => {
                          e.stopPropagation();
                          that.setState({
                            showApproveModal: true,
                            userDevice: item.id,
                          });
                        }}
                      >
                        Log Fault
                      </button>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <Modal
          isOpen={this.state.showDeleteModal !== false}
          onRequestClose={() => this.setState({ showDeleteModal: false })}
          contentLabel={"Delete Device"}
          className={"confirm-modal"}
        >
          <button onClick={() => this.setState({ showDeleteModal: false })} className={"confirm-modal-close"}>
            <MdClose />
          </button>
          <div className={"confirm-main-text"}>
            Are you sure you want to delete
            {this.state.myDevices[this.state.showDeleteModal] ? this.state.myDevices[this.state.showDeleteModal].name : "this device"}?
          </div>
          <div className={"confirm-sub-text"}>All associated warranty requests will also be deleted.</div>
          <div className={"approveDeclineSet"}>
            <button onClick={this.handleDelete} className={"decline"}>
              Yes
            </button>
            <button className={"neutral"} onClick={() => this.setState({ showDeleteModal: false })}>
              No
            </button>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.showIosPopup}
          onRequestClose={() => this.setState({ showIosPopup: false })}
          contentLabel={"Download not available on mobile."}
          className={"info-modal"}
        >
          <button onClick={() => this.setState({ showIosPopup: false })} className={"info-modal-close"}>
            <MdClose />
          </button>
          <div className={"info-main-text"}>Sorry but download is unavailable on mobile.</div>
          <div className={"info-sub-text"}>If you wish to see your proof of purchase document, please download on a computer.</div>
        </Modal>
        <Modal
          isOpen={this.state.addWarrantyModal !== false}
          onRequestClose={() => this.setState({ addWarrantyModal: false })}
          contentLabel={"Add Warranty Request"}
          className={"confirm-modal confirm-modal-big"}
          style={{
            content: { top: "calc(50% - 100px)", bottom: "calc(50% - 100px)" },
          }}
        >
          <button onClick={() => this.setState({ addWarrantyModal: false })} className={"confirm-modal-close"}>
            <MdClose />
          </button>
          <div className={"confirm-main-text"}>
            Are you sure you want to submit a warranty request for{" "}
            {this.state.myDevices[this.state.addWarrantyModal] ? this.state.myDevices[this.state.addWarrantyModal].name : "this device"}?
          </div>
          <div className={"confirm-sub-text"}>
            After submitting this request, a member of our warranty team will contact you via email on service@roborock.com.au to discuss your
            warranty needs.
          </div>
          <div className={"approveDeclineSet"}>
            <button onClick={this.handleAddWarranty} className={"approve"}>
              Yes
            </button>
            <button className={"neutral"} onClick={() => this.setState({ addWarrantyModal: false })}>
              No
            </button>
          </div>
        </Modal>

        <Modal
          isOpen={this.state.openModalHistory}
          onRequestClose={() => this.setState({ openModalHistory: false })}
          contentLabel={"Add Warranty Request"}
          className="input-modal"
        >
          <div className="card">
            <button onClick={() => this.setState({ openModalHistory: false })} className={"confirm-modal-close"}>
              <MdClose />
            </button>
            <h5 className="card-header">Device history</h5>
            <div className="card-body">
              {this.state.currentHistoryTicket.length === 0 ? (
                <tbody>
                  <tr>
                    <td colSpan="4" className="text-center">
                      No History found.
                    </td>
                  </tr>
                </tbody>
              ) : (
                <table className="table">
                  <thead style={{ backgroundColor: "#A94442", color: "white" }}>
                    <tr>
                      <th>Ticket Number</th>
                      <th>Status</th>
                      <th>Created</th>
                      <th>Ticket Type</th>
                    </tr>
                  </thead>
                  <tbody style={{ fontSize: "0.9rem", textAlign: "center" }}>
                    {this.state.currentHistoryTicket.map((ticket) => (
                      <tr key={ticket.id}>
                        <td>{ticket.ticket_number}</td>
                        <td style={{ wordWrap: "break-word" }}>{ticket.status}</td>
                        <td>{moment(ticket.created).format("DD MMMM YYYY HH:mm")}</td>
                        <td>{ticket.ticket_type.charAt(0).toUpperCase() + ticket.ticket_type.slice(1)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </Modal>
        <Modal isOpen={this.state.showShipping} contentLabel={"Export Report"} className={"fullpage-modal"}>
          <ShippingLabel
            protectedFetch={this.props.protectedFetch}
            ticket={this.state.ticket}
            handleCloseModal={() => this.setState({ showShipping: false })}
            ticketId={this.state.currentTicketId}
            userDevice={this.state.userDevice}
            // retrieveWarranty={() => this.retrieveWarranty()}
          />
        </Modal>
        {/* Modal form for approving warranty form */}
        <Modal isOpen={this.state.showApproveModal} className={"fullpage-modal"}>
          <SetWarrantyDetails
            userDevice={this.state.userDevice}
            protectedFetch={this.props.protectedFetch}
            showModal={this.state.showApproveModal}
            hideModal={() => this.setState({ showApproveModal: false })}
            retrieveDevices={this.retrieveDevices}
          />
        </Modal>
      </div>
    );
  }
}
