import React, { Component } from "react";
import "./SetWarrantyDetails.css";
import "../../common.css";
import Modal from "react-modal";
import config from "../../../config";
import { icons } from "../../../img/icons";
import Select from "react-select";
import { MdClose } from "react-icons/md";
Modal.setAppElement("body");

export default class SetWarrantyDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reason: [],
      submitProgress: false,
      redirect: false,
      isLoading: true,
      problemFiles: [],
      ticketType: null, // Default to top item in options list (shortcut work)
    };
  }

  handleCheckboxChange = (e) => {
    const { name, value, checked } = e.target;
    let values = this.state[name],
      valueExists = values.indexOf(value);

    if (checked) {
      // Add if doesn't exist
      if (valueExists === -1) {
        values.push(value);
      }
    } else {
      // Remove if does exist
      if (valueExists > -1) {
        values.splice(valueExists, 1);
      }
    }

    this.setState({ [name]: values });
  };
  handleInputChange = (e) => {
    const { id, value } = e.target;

    this.setState({
      [id]: value,
    });
  };

  handleTicketTypeChange = (e) => {
    this.setState({
      ticketType: e.value,
      selectedTicketType: e,
    });
  };
  handleFileUpload = (e) => {
    // TODO - verify file upload to make sure it's good
    const files = Array.prototype.slice.call(e.target.files);
    if (files.length > 5) {
      alert("Maximum file to be uploaded is only 5");
      files.splice(5, files.length - 5);
    }
    this.setState({ problemFiles: files });
    // const allowedTypes = ["image/jpeg", "image/png", "video/mp4", "video/webm", "video/quicktime"];

    // if (!allowedTypes.includes(files.type)) {
    //   alert("Only JPG, JPEG, PNG, WEBM, MOV and MP4  files are allowed.");
    //   this.setState({ allowUpload: false });
    // } else {
    //   this.setState({ allowUpload: true });
    // }
  };
  handleSubmit = (e) => {
    this.setState({ submitProgress: true });
    e.preventDefault();

    // Saya comment takutnya nanti diperlukan karena ada variable yang dikirim
    // tapi tidak ada di BE. Contohnya "assign_to"

    // let formData = new FormData();
    // formData.append("reason", JSON.stringify(this.state.reason));
    // if (this.state["ticketType"]) {
    //   formData.append("ticket_type", this.state["ticketType"]);
    // }
    // if (this.state["systemError"]) {
    //   formData.append("system_error", this.state["systemError"]);
    // }
    // if (this.state["otherIssue"]) {
    //   formData.append("other_issue", this.state["otherIssue"] || null);
    // }
    // if (this.state["userDevice"]) {
    //   formData.append("user_device", parseInt(this.state["userDevice"]) || 0);
    // }
    // if (this.state["assignedTo"]) {
    //   formData.append("assigned_to", this.state["assignedTo"] || null);
    // }

    // const payload = JSON.stringify(Object.fromEntries(formData));

    let formData = new FormData();
    formData.append("reason", this.state.reason);
    formData.append("ticket_type", this.state.ticketType || "warranty");
    formData.append("service", this.state.type_of_service);
    formData.append("system_error", this.state.systemError);
    formData.append("user_device", parseInt(this.props.userDevice) || 0);
    formData.append("other_issue", this.state.otherIssue);
    if (this.state.problemFiles?.length > 0) {
      this.state.problemFiles.forEach((file) => formData.append("media", file));
    }

    this.props
      .protectedFetch(config.backendServer + "ticket/new/", "POST", formData, (res) => res.ok, true)
      .then((res) => {
        alert("Success add ticket");
        this.props.hideModal();
        this.props.retrieveDevicesNew();
        this.setState({ submitProgress: false });
        window.location.href = "/";
      })
      .catch((e) => {
        console.log("Fail");
        this.setState({ submitProgress: false });
      });
  };

  handleRemoveFile = (name) => {
    const tempProblemFiles = this.state.problemFiles.filter((file) => file.name !== name);
    this.setState({ problemFiles: tempProblemFiles });
  };

  render() {
    const checkboxes = [
      "More noise than usual",
      "Bad smell/burning smell from the machine",
      "Can not connect and sync with mobile application",
      "Can not connect Bluetooth",
      "Can not charge the machine",
      "Slow charging/can not charge in full",
      "Spinning around in one place",
      "Wheels are not working",
      "Not going back to the charging dock (Note: Before deciding to proceed with warranty for this issue, please follow the troubleshooting instructions and make sure the machine has been reset/restarted properly. If our technicians can not find any fault with the machine and the issue is fixed by just a restart, you will be charged for the shipping fee.)",
    ];

    const ticketTypes = [
      {
        value: "warranty",
        label: "Warranty or Service Claims (within 1 - 2 years)",
      },
      { value: "out_of_warranty", label: "Service (Out of Warranty)" },
    ];

    return (
      <Modal
        isOpen={this.props.showModal}
        contentLabel={"Set warranty details"}
        className={"set-warranty-modal"}
        style={{
          /* z-index so it appears above mobile menu icon */
          overlay: { zIndex: 1001 },
        }}
      >
        <button onClick={this.props.hideModal} className={"confirm-modal-close"}>
          <MdClose />
        </button>
        <div className={"page add-new-warranty-page"}>
          <h2 className={"pageHeader"}>Warranty Request</h2>

          <div className={"pageContent"}>
            <form onSubmit={this.handleSubmit}>
              <div className="form-field">
                <label>Type of Service</label>
                <Select
                  id={"ticketType"}
                  required
                  options={ticketTypes}
                  onChange={(e) => this.handleTicketTypeChange(e)}
                  value={this.state.selectedTicketType}
                  style={{
                    /* z-index so it appears above mobile menu icon */
                    overlay: { zIndex: 1002 },
                  }}
                />

                {/* <select id={"ticketType"} onChange={this.handleInputChange} value={this.state.ticketType} required>
                  <option value="" key={-1}>
                    ---Select service---
                  </option>
                  {ticketTypes.map((option, j) => {
                    return (
                      <option key={j} value={option.value}>
                        {option.label}
                      </option>
                    );
                  })}
                </select> */}
              </div>

              <div className={"add-new-warranty-special-input-header"}>Please select and provide reason of return below:</div>
              {checkboxes.map((item, i) => {
                return (
                  <label
                    className={`add-new-warranty-checkbox-container ${item.length > 50 ? "add-new-warranty-checkbox-container-full-width" : ""}`}
                    key={i}
                  >
                    <input
                      type="checkbox"
                      id={"reason" + i}
                      name="reason"
                      value={item}
                      onChange={this.handleCheckboxChange}
                      required={this.state.reason.length < 1}
                    />
                    <span className={"add-new-warranty-checkbox-text"}>{item}</span>
                  </label>
                );
              })}

              <div className="form-field">
                <label>Is there a System Error? (Error 1-18)</label>
                <input type={"text"} onChange={this.handleInputChange} id={"systemError"} required />
              </div>
              <div className={"form-field"}>
                <label>Other issue, please describe in details</label>
                <textarea required onChange={this.handleInputChange} id={"otherIssue"} />
              </div>
              <div className={"form-field"}>
                <div className="mb-2">
                  To expedite your support process, kindly attach troubleshooting details such as video showcasing the issue, and clear pictures of
                  your device. Your thorough documentation ensures quicker and more accurate solutions
                </div>
                {/* <div>Please upload your proof of purchase</div> */}
                <label for="uploadProblemFiles" className="custom-file-upload">
                  <img src={icons.Upload} alt="" /> Choose Files
                </label>
                <span>{`  ${this.state.problemFiles ? "" : "No file choosen"}`}</span>
                <input
                  accept="image/jpeg, image/png, video/mp4, video/webm, video/quicktime"
                  type={"file"}
                  id={"uploadProblemFiles"}
                  multiple
                  // className="custom-file-upload"
                  onChange={this.handleFileUpload}
                />
              </div>
              <div className="d-flex flex-row w-100 overflow-auto">
                {this.state.problemFiles &&
                  this.state.problemFiles.map((file) => {
                    if (file.type.includes("image")) {
                      return (
                        <div className="m-2 border ">
                          <div className="text-end">
                            <button type="button" className="btn btn-outline-danger btn-sm m-1 py-0" onClick={() => this.handleRemoveFile(file.name)}>
                              x
                            </button>
                          </div>

                          <img src={URL.createObjectURL(file)} title={file.name} alt="" height={120} width={120} />
                        </div>
                      );
                    } else {
                      return (
                        <div className="m-2 border ">
                          <div className="text-end">
                            <button type="button" className="btn btn-outline-danger btn-sm m-1 py-0" onClick={() => this.handleRemoveFile(file.name)}>
                              x
                            </button>
                          </div>
                          <video src={URL.createObjectURL(file)} title={file.name} height={120} width={120} />
                        </div>
                      );
                    }
                  })}
              </div>
              <button disabled={this.state.submitProgress} className="k-btn k-btn-primary-full" type="submit">
                Submit
              </button>
            </form>
          </div>
        </div>
      </Modal>
    );
  }
}
