import React, { Component } from "react";
import "./UserProfile.css";
import "../common.css";
// import "../simple-grid.css";
import config from "../../config";
import errorFunctions from "../../store/errorFunctions";
import utilityFunctions from "../../store/utilityFunctions";
import LoadingPage from "../../components/Loading/Loading";
import { MdClose } from "react-icons/md";
import { Redirect } from "react-router-dom";
import Modal from "react-modal";
Modal.setAppElement("body");

export default class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      isLoading: false,
      showSuccess: false, // On update, show modal to show 'Data Saved'
      showFailure: false,
      fieldsEdited: false,
      first_name: "",
      last_name: "",
      company: "",
      address_line_one: "",
      address_line_two: "",
      state: "",
      suburb: "",
      postcode: "",
      mobile: "",
      // Only allow button click when something has been edited
    };
    this.lgnToken = localStorage.getItem("lgn") || utilityFunctions.getCookie("lgn");
    this.idUser = JSON.parse(this.lgnToken)?.user.id;
  }

  componentDidMount() {
    this.retrieveProfile();
  }

  retrieveProfile = () => {
    this.setState({ isLoading: true });
    this.props
      .protectedFetch(config.backendServer + `customers/${this.idUser}/`, "GET", null, errorFunctions.checkResponseThenJson)
      .then((res) => {
        console.log(res);
        this.setState({
          profile: res,
          fieldsEdited: false,
          first_name: res.first_name,
          last_name: res.last_name,
          company: res.company,
          address_line_one: res.address_line_one,
          address_line_two: res.address_line_two,
          state: res.state,
          suburb: res.suburb,
          postcode: res.postcode,
          mobile: res.mobile,
          isLoading: false,
        });
        // let stateToSet = { isLoading: false };
        // for (const field of this.inputFields) {
        //   stateToSet[field.id] = res[field.id] || "";
        // }
        // this.setState(stateToSet);
      })
      .catch(() => {
        console.log("fail");
        this.setState({ isLoading: false });
      });
  };
  handleInputChange = (e) => {
    const { id, value } = e.target;

    this.setState({
      [id]: value,
      fieldsEdited: true,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });

    let dataToSend = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      company: this.state.company,
      address_line_one: this.state.address_line_one,
      address_line_two: this.state.address_line_two,
      state: this.state.state,
      suburb: this.state.suburb,
      postcode: this.state.postcode,
      mobile: this.state.mobile,
    };

    this.props
      .protectedFetch(config.backendServer + `customers/${this.idUser}/`, "PATCH", dataToSend, (res) => res.ok)
      .then((res) => {
        console.log(res);
        // On resolved and rejected, need to take off loading screen
        this.setState({
          showSuccess: true,
          isLoading: false,
          fieldsEdited: false,
        });
      })
      .catch(() => {
        this.setState({
          showFailure: true,
          isLoading: false,
          fieldsEdited: false,
        });
      });
  };

  render() {
    if (this.state.isLoading) {
      return <LoadingPage />;
    }
    if (this.state.showSuccess && this.props.location.state && this.props.location.state.goToDevice) {
      // If success and coming from click on load (setting up profile view)
      return <Redirect to={"/addnewdevice"} />;
    }
    if (this.state.showSuccess) {
      // If success and coming from click on load (setting up profile view)
      return <Redirect to={"/"} />;
    }

    // const inputFields = this.inputFields;
    return (
      <div className={"page"}>
        <h2 className={"pageHeader"}>My Details</h2>
        <div className={"pageContent"}>
          <form onSubmit={this.handleSubmit}>
            <div className="form-field row">
              <div className="col">
                <label>First Name *</label>
                <input
                  type={"text"}
                  value={this.state.first_name}
                  required={true}
                  onChange={this.handleInputChange}
                  id="first_name"
                  placeholder="Input first name"
                />
              </div>
              <div className="col">
                <label>Last Name *</label>
                <input type={"text"} value={this.state.last_name} required={true} onChange={this.handleInputChange} id="last_name" />
              </div>
            </div>
            <div className="form-field row">
              <div className="col">
                <label>{"Company (if any)"}</label>
                <input type={"text"} value={this.state.company} onChange={this.handleInputChange} id="company" placeholder="Input company name" />
              </div>
            </div>
            <div className="form-field row">
              <div className="col">
                <label>Address line 1 </label>
                <input
                  type={"text"}
                  value={this.state.address_line_one}
                  required={true}
                  onChange={this.handleInputChange}
                  id="address_line_one"
                  placeholder="Input address line"
                />
              </div>
            </div>
            <div className="form-field row">
              <div className="col">
                <label>Address line 2</label>
                <input
                  type={"text"}
                  value={this.state.address_line_two}
                  onChange={this.handleInputChange}
                  id="address_line_two"
                  placeholder="Input address line"
                />
              </div>
            </div>
            <div className="form-field row">
              <div className="col">
                <label>State</label>
                <input
                  type={"text"}
                  value={this.state.state}
                  required={true}
                  onChange={this.handleInputChange}
                  id="state"
                  placeholder="Input state"
                />
              </div>
              <div className="col">
                <label>Suburb</label>
                <input
                  type={"text"}
                  value={this.state.suburb}
                  required={true}
                  onChange={this.handleInputChange}
                  id="suburb"
                  placeholder="Input suburb"
                />
              </div>
              <div className="col">
                <label>Postcode</label>
                <input
                  type={"text"}
                  value={this.state.postcode}
                  required={true}
                  onChange={this.handleInputChange}
                  id={"postcode"}
                  placeholder="Input postcode"
                />
              </div>
            </div>
            <div className="form-field row">
              <div className="col ">
                <label>Phone</label>
                <input
                  type={"text"}
                  value={this.state.mobile}
                  required={true}
                  onChange={this.handleInputChange}
                  id="mobile"
                  placeholder="Input phone"
                />
              </div>
            </div>
            <div className="form-field row">
              <button onClick={() => (window.location.href = "/")} className={"k-btn k-btn-outline-primary col m-1 text-decoration-none"}>
                Cancel
              </button>
              <button className="k-btn k-btn-primary col m-1" type="submit" disabled={!this.state.fieldsEdited}>
                {this.props.location.state && this.props.location.state.goToDevice ? "Next" : "Update"}
              </button>
            </div>
          </form>
        </div>
        <Modal
          isOpen={this.state.showSuccess}
          onRequestClose={() => this.setState({ showSuccess: false })}
          contentLabel={"Profile has been updated."}
          className={"info-modal"}
        >
          <button onClick={() => this.setState({ showSuccess: false })} className={"info-modal-close"}>
            <MdClose />
          </button>
          <div className={"info-main-text"}>Thank you</div>
          <div className={"info-sub-text"}>Your profile has been updated.</div>
        </Modal>
        <Modal
          isOpen={this.state.showFailure}
          onRequestClose={() => this.setState({ showFailure: false })}
          contentLabel={"Something has gone wrong."}
          className={"info-modal"}
        >
          <button onClick={() => this.setState({ showFailure: false })} className={"info-modal-close"}>
            <MdClose />
          </button>
          <div className={"info-main-text"}>Something has gone wrong</div>
          <div className={"info-sub-text"}>Please refresh the page before continuing.</div>
        </Modal>
      </div>
    );
  }
}
